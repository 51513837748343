import {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {UserContext} from "./User";

export const ApiContext = createContext(null);

const API_URL = 'https://cibiņš.burzum.lv/api/';
let oldLocation = '';

export const ApiProvider = props => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchOrder, setSearchOrder] = useState(1);
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalProducts, setTotalProducts] = useState(0);
    const [searchInProgress, setSearchInProgress] = useState(false);

    let location = useLocation();
    let navigate = useNavigate();

    const [prevLocation, setPrevLocation] = useState(null);

    const {User} = useContext(UserContext);

    useEffect(() => {
        if (JSON.stringify(prevLocation) !== JSON.stringify(location)) {
            setPrevLocation(location);
        }
    }, [location, prevLocation]);


    const Api = {
        URL: API_URL,
        get: config => {
            const {method, params} = config;
            const url = new URL(API_URL + method);

            // Append query parameters
            if (params) {
                Object.keys(params).forEach((key) => {
                    url.searchParams.append(key, params[key]);
                });
            }

            const pageUrl = new URL(document.location.href);
            if (pageUrl.searchParams.get('key')) {
                url.searchParams.set('key', pageUrl.searchParams.get('key'));
            }

            return fetch(url.toString())
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not OK');
                    }
                    return response.json().then((data) => {
                        if (data.status === 403) {
                            window.location.reload();
                            return Promise.reject(new Error(data.error));
                        }
                        return Promise.resolve(data);
                    });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    throw error;
                });
        },
        post: config => {
            const {method, params, data} = config;
            const url = new URL(API_URL + method);

            // Append query parameters
            if (params) {
                Object.keys(params).forEach((key) => {
                    url.searchParams.append(key, params[key]);
                });
            }

            const pageUrl = new URL(document.location.href);
            if (pageUrl.searchParams.get('key')) {
                url.searchParams.set('key', pageUrl.searchParams.get('key'));
            }

            // Formulate the request body data
            const formData = new URLSearchParams();
            if (data) {
                Object.keys(data).forEach((key) => {
                    if (Array.isArray(data[key])) {
                        data[key].forEach((item, index) => {
                            formData.append(`${key}[${index}]`, item);
                        });
                    } else {
                        formData.append(key, data[key]);
                    }
                });
            }

            return fetch(url.toString(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData.toString()
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not OK');
                    }
                    return response.json().then((data) => {
                        if (data.status === 403) {
                            window.location.reload();
                            return Promise.reject(new Error(data.error));
                        }
                        return Promise.resolve(data);
                    });
                })
                .catch((error) => {
                    console.error('Error:', error);
                    throw error;
                });
        },


        doSearch: (page) => {
            let _searchTerm = searchTerm;
            let _searchOrder = searchOrder;
            let pageWasObject = false;
            if (typeof page === 'object') {
                _searchTerm = page.term;
                _searchOrder = page.order ? page.order : _searchOrder;
                setSearchOrder(_searchOrder);
                pageWasObject = page;
                page = page.page;
            }
            if (/\b\w{3,}\b/.test(_searchTerm)) {
                setSearchInProgress(true);
                setCurrentPage(page);
                const url = new URL(document.location.href);
                url.searchParams.set('q', _searchTerm);
                url.searchParams.set('p', String(page));
                url.searchParams.set('o', String(_searchOrder));
                navigate({
                    pathname: '/meklesana/',
                    search: url.search
                }, {replace: pageWasObject&&!pageWasObject.noReplace, state: {showSearchingNotification: !!pageWasObject.noReplace}});
                window.scrollTo({top: 0, behavior: 'smooth'});
                Api.get({
                    method: 'get_products.php',
                    params: {q: _searchTerm, page, order: _searchOrder}
                }).then(result => {
                    setSearchResults(result.products);
                    setTotalPages(result.pages);
                    setTotalProducts(result.total);
                    setSearchInProgress(false);
                });
            }
        },


        get searchTerm() {
            return searchTerm;
        },
        updateSearchTerm: setSearchTerm,
        get searchOrder() {
            return searchOrder;
        },
        updateSearchOrder: order => {
            setSearchOrder(order);
        },
        get currentPage() {
            return currentPage;
        },
        changePage: page => {
            Api.doSearch(page);
        },
        get searchResults() {
            return searchResults;
        },
        get totalPages() {
            return totalPages;
        },
        get searchInProgress() {
            return searchInProgress;
        },
        get totalProducts() {
            return totalProducts;
        },


    };

    const Location = {};

    useEffect(() => {
        // Make API Call here
        if (searchOrder) {  // check if searchOrder is not empty or undefined
            Api.doSearch(1);
        }
    }, [searchOrder]); // dependency array. This useEffect reruns whenever `searchOrder` changes


    useEffect(() => {
        if (oldLocation === '/meklesana/' && location.pathname !== oldLocation) {
            console.log(oldLocation, location);
            setSearchTerm('');
        }
        oldLocation = location.pathname;
    }, [location, navigate]);

    useEffect(() => {
        Api.get({method: 'session.php'}).then(response => {
            User.authenticated = response.user !== null;
            User.scopes = response.scopes;
        });
    }, []);


    return <ApiContext.Provider value={{Api, Location}}>
        {props.children}
    </ApiContext.Provider>;
};
